<template>
  <b-card class="px-3 py-2">
    <b-card-title>
      <h3>Códigos Postales Activos - Cambio y Devolución</h3>
    </b-card-title>
    <b-card-body>
      <b-row class="mb-2">
        <b-form @submit="onSubmit" inline>
          <b-form-group>
            <b-form-file v-model="form.file" accept=".xls, .xlsx, .csv" ref="file">
            </b-form-file>
          </b-form-group>
          <b-button
            ref="submitButton"
            style="font-size: 1rem !important;"
            class="ml-2 btn-sm"
            type="submit"
            :disabled="!form.file"
          >
            <feather-icon
              size="15"
              icon="PlusIcon"
              class="mr-50"
            />
            <span style="font-size: 15px">Agregar Adjunto</span>
          </b-button>
          <button class="btn btn-sm button-primary ml-2" @click="exportToExcel" type="button">
            <feather-icon
              size="15"
              icon="DownloadIcon"
              class="mr-50"
            />
            <span style="font-size: 15px">Descargar CP</span>
          </button>
        </b-form>
      </b-row>

      <b-row v-if="postalCode.length > 0">
        <div class="d-inline-flex justify-content-around flex-wrap badge-group">
          <b-badge style="width: 40px; color: #7b7878; background-color: rgba(227, 228, 230, 0.8);" v-for="p in postalCode" :key="p">
            {{ p }}
          </b-badge>
        </div>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as XLSX from "xlsx";
import SettingService from "@/services/SettingService";
import AlertService from "@/services/AlertService";
import { BCard, BCardTitle, BCardBody, BRow, BForm, BFormGroup, BFormFile, BButton, BBadge } from 'bootstrap-vue'

export default {
  name: "Setting",
  components: {
    BCard, 
    BCardTitle, 
    BCardBody, 
    BRow, 
    BForm, 
    BFormGroup, 
    BFormFile, 
    BButton,
    BBadge
  },
  data() {
    return {
      isLoading: false,
      postalCode: [],
      form: {
        file: null,
      },
    };
  },
  methods: {
    /**
     *
     */
    exportToExcel() {
      const fileName = "postal_code.xlsx";
      const ws = XLSX.utils.json_to_sheet(
        this.postalCode.map((d) => {
          return { codigopostal: d };
        })
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "postal");
      XLSX.writeFile(wb, fileName);
    },

    /**
     *
     */

    saveList() {
      this.isLoading = true;
      SettingService.setPostalCode({
        postal_code: this.postalCode,
      })
        .then(() => {
          this.isLoading = false;
          AlertService.info();
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Actualizar`,
              text: `Error al tratar de almacenar`,
              icon: "AlertOctagonIcon",
              variant: "danger",
            },
          });
        });
    },

    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();

      this.isLoading = true;

      const readtWithString = (wb, name) => {
        const elements = XLSX.utils.sheet_to_json(wb.Sheets[name]).map((item) => {
          const val = {};
          Object.keys(item).forEach((key) => {
            const key2 = key.toLowerCase().replace(/\s/g, "");
            val[key2] = String(item[key]).trim();
          });
          return val;
        });
        return elements;
      };
      const file = this.form.file;
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        this.postalCode = readtWithString(wb, wb.SheetNames[0])
          .map((p) => {
            return p.codigopostal;
          })
          .filter((p) => p);
        this.saveList();
        this.isLoading = false;
      };
      reader.readAsBinaryString(file);
    },
  },
  async mounted() {
    try {
      this.postalCode = (await SettingService.postalCode()).data;
    } catch (error) {
      console.error(error);
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Configuración`,
          text: `Error al tratar de cargar información`,
          icon: "AlertOctagonIcon",
          variant: "danger",
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
// .badge-group{
//   height: 400px; 
//   overflow-y: auto; 
//   overflow-x: hidden !important;
// }
// .badge{
//   margin: 5px; 
//   background-color: rgb(228, 228, 240); 
//   color: rgb(121, 121, 124); 
//   font-weight: 700;
//   border-radius: 0;
// }
</style>
